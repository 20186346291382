$black: #000000;
$white: #ffffff;
$background-gray: #f7f8fa;
$primary-color: #ffb22b;
$input-background-gray: #f2f4f9;
$border-light-grey: #e6e9f0;
$secondary-color: #4d4edb;
$danger-color: #ff6362;
$button-gray-color: #ebebff;
$checkbox-background-green: #0fdf90;
$modal-background-color: #f8f8f8;
$label-color: #ACADB0;
$checkbox-background-grey: #d9d9d9;
$verify-notification-background-primary: #ff9e00;
$verify-notification-background-secondary: #ff6000;
$reorder-background-color: #ECF6FE;
$dropzone-background-color: #279af1;
$input-background-light-gray: #f5f5f5;

$activity-border: #a9a9a9;
$card-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.07);
$img-background: rgba(242, 244, 249, 0.5);
$activity-border: #a9a9a9;
$text-secondary-color: rgba(0, 0, 0, 0.3);
$radio-button-color: rgba(0, 0, 0, 0.65);
$radio-button-checked-background-color: #ececec;
$border-color: rgba(0, 0, 0, 0.1);
$background-no-location: #25b8f4;
$icon-color-no-location: #0584e9;
$addbar-shadow-box: 0 2px 26px 0 rgba(0, 0, 0, 0.16);
$text-color-blue: #6c63ff;
$text-color-grey: #f6f6f6;
$open-map-btn-color: #ffa303;

$clipboard-text-color: #8c8c8c;
$split-blue-color: #00008b;

$thick-black: #151515;

$medium-green: #00c151;
$light-green: #0ede90;

$light-black: #4f4f4f;
$thick-grey: #777777;
$mild-green: #34c759;
$mild-grey: #d8d8d8;
$danger-red: #ff6363;
$mild-orange: #ff9d00;

$light-grey: #c3c8d4;
$light-violet: #d9d9ff;

$light-background-grey: #f1f3f8;
$thick-blue: #729ccc;
$thick-green: #74998e;
$light-grey-value: #828282;
$grey: #666666;
$mild-blue: #007aff;

$home-title-green: #e4f0ed;
$hover-transform-effect: translateX(-2px) translateY(-2px);
$hover-box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.12);
$tooltip-grey: #6f7376;
$home-grey: #d9dbe0;
$home-dark-grey: #bbbbbb;
$dark-grey: #a6a6a6;

$medium-grey: #b5b5b5;
$light-caramel: #f8ebd4;
$home-light-grey: #979797;
$light-blue: #c3ddfa;
$silver: #bfbfbf;
$dashed-border-light-grey: #c1c1c1;
$bisque: #ffe4c1;
$floral-white: #fffbf3;
$light-ash: #dfdfdf;

$pink: #b5b4b4;

$addbar-box-shadow: 0px -2px 50px rgba(0, 0, 0, 0.08);
$bar-view-shadow: 0px 1px 7px rgba(84, 84, 84, 0.12);
$selected-group-icon-background: linear-gradient(180deg, rgba(239, 241, 246, 0) 0%, rgba(239, 241, 246, 0.915019) 27.85%, #EFF1F6 94.79%);
$folder-group-icon-background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 27.6%, #FFFFFF 94.79%);
$raisin-black: #221d1f;
$pale-orange: #fff7ee;
$gray: #787979;
$dark-pink: #454243;
$light-silver: #cccccc;
$navy: #000181;
$charcoal: #383838;
$brown: #ffa303;
$dark-gray: #404040;
$mustard: #f39e2b;
$social-user-alert-box-shadow: 0 2px 64px rgba(0, 0, 0, 0.2);

$screen-lg: 1024px;

$folder-default-width: 280px;
$folder-default-height: 450px;
$folder-compact-height: 150px;
$reduced-folder-width: 300px;

$dark-black: #222222;
$pale-blue: #f8faff;
$pale-grey: #848484;
$pale-black: #353535;
$shade-grey: #e6e6e6;
$lotion: #fbfbfc;
$bright-gray: #eceef3;
$blue-chalk: #eaecf2;
$mercury: #e2e5ed;
$geyser: #dcdfe8;
$iron: #d2d6df;
$lavender: #dde0e8;
$browser-warm-load-modal-box-shadow: 0px 2px 64px rgba(0, 0, 0, 0.2);
$light-silver: #d6d8dd;
$caramel: #ffdca0;
$light-salmon-pink: #ffa69b;
$bright-grey: #e9ebf1;
$pale-gray: #e4e6ed;
$gainsboro: #dcdfe6;
$dark-gainsboro: #d9dde8;
$platinum: #e5e5e5;
$lavender-gray: #c3c5cc;
$floral-light-white: #fffaef;
$blanched-almond: #ffeccc;
$bisque-light: #fce7c3;
$pastel-gray: #d2cec4;
$white-coffee: #e9e3d3;
$tealish-blue: #f9faff;
$nebula: #d8d8d9;
$silver-sand: #c2c2c2;

// New colors - Revamping to new colors for new hierarchical structure
$blue-gray: #4E5F7E;
$light-blue-gray: #D9DFE8;
$light-gray: #F2F4F7;
$soft-blue: #D4DAED;
$slate-blue: #8D9DB9;
$dark-blue: #2B4E78;
$cool-gray: #A6B3C9;
$brand-blue: #0B1242;
$light-ash-grey: #E2E2E2;
$light-ash-grey-2: #E0E0E0;
$filter-color: #8D9DB9;

// font Weights
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;


//dividerColors
$cloudarea-card-withinStats: #BFC9D9;
$foldersHeader-withinOptions: #D9DFE8;

//searchResult's Detail Section Items UI Colors
$item-BoxShadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.07);
$item-video-playicon: #FFFFFF2D;
$item-audio-caption-text: #0000004d;
$item-url-caption-background: #FFFFFFCC;
$item-url-caption-text: #323232;

$onboarding-footer-hover: #0965AE;
$pro-tip-hover: #FFEF9D;
