@import "src/styles/variables";

.paste-modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
    float: unset;
    margin-right: unset;
    button.secondary {
      background-color: $background-no-location !important;
      color: $white !important;
      border: none !important;
    }
  }
}

.banner-message {
  display: flex;
  width: 100%;
  z-index: 201;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;

  &.delete-success-banner {
    position: relative;
  }

  &.info {
    .banner-info-icon {
      background-color: $icon-color-no-location;
    }
  }

  &.error {
    .banner-icon {
      img, svg {
        cursor: pointer !important;
      }
    }
  }

  &.info, &.error {
    display: flex;
    //justify-content: space-between;
    .banner-info-icon {
      background-color: $icon-color-no-location;
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      img, svg {
        width: 18px;
        height: 18px;
      }
    }

    .banner-info-text {
      padding: 8px 0 8px 10px;
      font-size: 14px;
      font-weight: 600;
      color: $black;
      flex-grow: 2;
    }

    .info-close-icon {
      padding: 7px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.alert-off {
    transform: scaleY(0);
  }

  &.display-off {
    display: none;
  }

  &.success {
    background-color: $light-green;

    .banner-icon {
      background-color: $medium-green;
    }
  }

  &.info {
    background-color: #25b8f4;

    .banner-icon {
      background-color: #0584e9;
    }
  }

  &.error {
    background-color: $danger-red;

    .banner-icon {
      background-color: #ff2626;
      pointer-events: auto;
    }
  }

  .banner-icon {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    img, svg {
      height: 18px;
      width: 18px;
    }
  }

  .banner-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 10px 9px 10px;
    color: $black;
  }
}
.ant-notification:has(.custom-alert) {
  position: absolute !important;
}

.custom-alert {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  width: 100%;

  &.w-50 {
    width: 50vw!important;
  }

  &.success {
    border: 2px solid #A3F5D6;
    .ant-notification-notice-message {
      color: #14B879;
    }
  }
  &.failure {
    border: 2px solid #FD9B9B;
    .ant-notification-notice-message {
      color: #F10404;
    }
  }
  &.info {
    border: 2px solid #9ED1FA;
    .ant-notification-notice-message {
      color: $black;
    }
  }
  &.warning {
    border: 2px solid #FFDA99;
    .ant-notification-notice-message {
      color: #FFBC47;
    }
  }
  .ant-notification-notice-message {
    margin: 0;
    .content {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        display: block;
      }
      span {
        vertical-align: middle;
        font-size: 16px;
        display: block;
      }
      .d-flex {
        span {
          margin: auto;
        }
      }
      .main-message {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        & > div:first-child {
          margin-right: 15px;
        }
      }
      .optional-go-to{
        cursor: pointer;
        margin-right: 8px;
        text-decoration: underline;
        text-align: right;
      }
    }
  }
  .ant-notification-notice-close {
    right: 12px;
    top: 15px;
    .ant-notification-notice-close-x {
      vertical-align: middle;
    }
  }
}

.d-flex {
  display: flex;
}

.froala-editor-modal {
  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    .ant-modal-body {
      padding: 32px;
      .ant-btn-primary , .ant-btn-secondary {
        height: 48px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        width: 100%;
        font-weight: 500;
      }
      .ant-btn-primary {
        background-color: $dropzone-background-color;
        border-color: $dropzone-background-color;
        &:disabled {
          border: 1px solid #ebebeb;
          background: #cccdcf;
        }
      }
      .ant-btn-secondary {
        background: $input-background-gray;
        border-color: $input-background-gray;
        font-weight: 500;
        &:hover {
          background: #cccdcf;
          color: black;
        }
      }
      .ant-typography-secondary {
        font-size: 10px;
        line-height: unset;
        display: block;
        margin: 5px 0 16px 0;
      }
      label {
        color: black;
        font-weight: 500;
      }
      .ant-radio-button-wrapper {
        text-align: center;
        height: auto;
        width: 118px;
        &:first-child {
          border-radius: 8px 0 0 8px;
        }
        &:last-child {
          border-radius: 0 8px 8px 0;
        }
        .group {
          line-height: 1.8;
          div {
            line-height: 1;
            margin-top: 8px;
          }
          .word-limit {
            color: $slate-blue;
            font-weight: unset;
            font-size: 10px;
            display: block;
            margin: 2px 0 6px;
          }
        }
        &.ant-radio-button-wrapper-checked {
          background: #FFF8EB;
          .group {
            div {
              color: $open-map-btn-color;
            }
          }
        }
      }
      input {
        height: 48px;
        padding: 12px 16px;
        border-radius: 8px;
      }
      .ant-form-item:last-child {
        margin: 0;
      }
    }
  }
}

.m-x-8 {
  margin: 0 8px;
}
.disabled-preview-action-icon{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}