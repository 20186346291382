@import "src/styles/variables";

.folder-list {
  position: relative;
  &.selected-node {
    #dragging-over {
      border: 2px solid $thick-blue !important;
      border-radius: 2px;
    }
    #dragging-home-over {
      border: 2px solid $thick-green !important;
      border-radius: 2px;
    }
    .banner {
      opacity: 0.1;
      pointer-events: none;
    }
  }
  .success {
    width: 50%;
    height: 32px;
    position: absolute;
    top: 12%;
    left: 25%;
  }
}

.item {
  &.SortableHelper {
    .ant-badge {
      width: inherit;
      height: inherit;

      .ant-scroll-number-custom-component {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        img, svg {
          width: 18px;
          height: 18px;
          margin: 0px auto;
          position: relative;
          top: 6px;
          left: 6px;
        }
      }
    }
  }
}

.myorder-folder {
  .folder-outer {
    & + .delete-restore-container {
      left: 16px;
    }
  }
}