@import "variables";
@import "buttons";

html, body, #root, #root > section, #root > div, .ant-layout {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  &:not(.site-layout) {
    display: none;
  }
}

#root {
  overflow: hidden;
  overflow-y: -webkit-paged-x;   // overflow issue in safari
  .folder-dragging {
    div, span, a, img, svg {
      cursor: grabbing !important;
    }
  }
}

/* Hide scrollbar for IE and Edge */
*:not(.site-layout):not(.content):not(.jots-context-menu) {
  scrollbar-width: none;
}

* {
  -ms-overflow-style: none;
  box-sizing: border-box;
}

body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Roboto;
}

input,
textarea {
  -moz-user-select: text;
}

.ant-layout {
  min-height: 100%;
  background-color: var(--surface-theme-light);
  #captures {
    overflow: auto;
    height: 100%;
    background-color: $white;
  }
}
.ant-notification {
  transform: translateX(0) !important;
  z-index: 10000;
  .ant-notification-notice {
    &.custom-alert {
      padding: 10px !important;

      .ant-notification-notice-message {
        margin: 0;
      }

      a {
        top: 50% !important;
        transform: translate(50%, -50%);
      }
    }
  }
}
#captureModalMount:not(.fullSection) {
  .ant-notification {
    transform: translateX(0) !important;
    .ant-notification-notice-wrapper {
      .ant-notification-notice {
        width: 218px;
        a{
          top: 50%;
          transform: translate(50%, -50%);
        }
      }
      .custom-alert {
        &.success {
          padding: 10px !important;
          .ant-notification-notice-message {
            margin: 0;
          }
        }
      }
    }
  }
}
.ant-card-body {
  .card-title {
    font-weight: 500;
    color: $black;
    margin-bottom: 40px;
    display: flex;
    .payment-pending{
      display: flex;
      align-items: center;
      background: #FFE590;
      border-radius: 4px;
      color: #7A4E00;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      padding: 0 8px;
    }
  }
}

.ant-modal-body {
  .modal-title {
    font-size: 22px;
    font-weight: 600;
    color: $black;
    margin-bottom: 40px;
  }

  .item-label {
    margin-bottom: 10px;
  }
}

.tab-layout {
  overflow: auto;
  height: 100%;

  &.activities {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.folders {
    .ant-modal-mask {
      height: 100%;
    }

    .ant-badge-not-a-wrapper {
      position: absolute;
      top: -13px;
      left: auto;
      right: 0;
      z-index: 1;
      width: 17px;
      height: auto;
    }

    .rnd-folder {
      .ant-badge-not-a-wrapper {
        z-index: 2;
      }
      &.folder-bar-view {
        height: 50px;
        min-height: 50px !important;
        max-height: 50px !important;
        .bar-view {
          display: flex;
          border-radius: 10px;
          box-shadow: $bar-view-shadow;
          &.home-folder {
            &:hover {
              border: solid 1px $thick-green;
            }
          }
          &.board-folder {
            &:hover {
              border: solid 1px $thick-blue;
            }
          }
        }
      }
    }

    .sparks-only {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .reduce-height {
        height: auto !important;

        .folder-outer {
          height: auto !important;
        }
      }

      &.compact {
        .folder-outer {
          height: 190px;
        }
      }

      .folder-outer {
        .folder-outlet {
          min-width: $folder-default-width;
          width: 100%;
          height: 100%;
        }
      }

      .ant-badge {
        .ant-scroll-number-custom-component {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0px;
          right: 0px;
          border-radius: 50%;

          img, svg {
            width: 18px;
            height: 18px;
            margin: 0px auto;
            position: relative;
            top: 6px;
            left: 6px;
          }
        }
      }
    }

    .my-order-container {
      display: flex!important;
      flex-wrap: wrap;
      width: 100%;
      gap: 24px;

      .item {
        display: flex;
        padding: 0 16px;
        position: relative;
        max-width: $folder-default-width;
        margin-right: 20px;
        margin-top: 20px;
        height: $folder-default-height;
        flex-basis: 100%;
        border-radius: 2px;
        flex-direction: column;
        transition: 0.3s height;

        .ant-badge {
          height: inherit;

          .ant-scroll-number-custom-component {
            width: 30px;
            height: 30px;
            border-radius: 50%;

            img, svg {
              width: 18px;
              height: 18px;
              margin: 0px auto;
              position: relative;
              top: 6px;
              left: 6px;
              cursor: pointer;
            }
          }
        }

        &.compact {
          height: $folder-compact-height;
        }

        &.folder-bar-view {
          height: 50px;
          min-height: 50px !important;
          max-height: 50px !important;
          .bar-view {
            display: flex;
            border-radius: 10px;
            box-shadow: $bar-view-shadow;
            .ant-card-body {
              height: 42px;
              display: flex;
              align-items: center;
              padding: 0;
              .icon-background {
                width: 42px;
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--hovering-light);
                border-radius: 10px 0 0 10px;
              }
              .home-icon-background {
                width: 42px;
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $light-ash-grey;
                border-radius: 10px 0 0 10px;
              }
              .group-icons {
                display: flex;
                gap: 10px;
              }
              .more-icon {
                width: 12px;
                img, svg {
                  width: inherit;
                }
              }
              .title {
                width: 150px;
                display: flex;
                flex-direction: column;
                padding-left: 11px;
                .text {
                  width: 100px;
                  font-size: 12px;
                  font-weight: 500;
                  overflow: hidden;
                  white-space: nowrap;
                }
                div {
                  font-size: 10px;
                  color: $home-light-grey;
                }
              }
            }
            &.home-folder {
              &:hover {
                border: solid 1px $thick-green;
              }
            }
            &.board-folder {
              &:hover {
                border: solid 1px $thick-blue;
              }
            }
          }
        }
      }

      .no-transform {
        transform: unset !important;
      }

      .SortableList {
        position: relative;
        z-index: 0;
        background-color: #F3F3F3;
        border-radius: 3px;
        outline: none;
        width: 400px;
        height: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        border: 1px solid $light-ash-grey-2;
        list-style: none;
        padding: 0;
      }

      .SortableItem {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        background-color: $white;
        border-bottom: 1px solid #EFEFEF;
        box-sizing: border-box;
        user-select: none;
        color: #333;
        font-weight: 500;
      }

      .SortableHelper {
        box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
        background-color: rgba(255, 255, 255, 0.8);
        cursor: row-resize;
      }

      .folder-list {
        width: 100%;
      }
    }

    .archived-folder {
      .icons {
        .hide-action {
          img, svg {
            cursor: default !important;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.content {
    background-color: var(--surface-theme-light);
    position: relative;

    &.folders {
      flex: 1;
    }

    &:not(:first-child) {
      margin-left: 0;
    }
    &.reduce-height {
      height: calc(100vh - 180px);
    }
  }

  .scroll-activity-container {
    overflow: auto;
    height: calc(100% - 75px);
    padding: 5px 15px 0 10px;

    .separator {
      padding-top: 15px;
      font-size: 18px;
      color: $black;
      opacity: 0.5;
      font-weight: bold;
    }
    .ant-empty {
      height: 200px;
    }
    &.activity-skeleton-container {
      .ant-list-item {
        padding: 0;
      }
      .activity-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            height: 83px;
            margin-top: 0;
            border-radius: 8px;
          }
        }
      }
    }
  }

}

.ant-layout-content.app-content {
  height: calc(100% - 80px);
}

// Overrides css for autofill background issue
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: transparent !important;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.cursor-pointer {
  cursor: pointer !important;
}

//empty
.ant-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes alertShow {
  0% {
    top: -33px;
  }
  15% {
    top: 0;
  }
  80% {
    top: 0;
  }
  100% {
    top: -33px;
  }
}

.ant-upload {
  .ant-avatar {
    cursor: pointer;
  }
}

.ril__outer {
  background: $black !important;
}

.ril__caption, .ril__toolbar {
  background: rgba(0, 0, 0, 0.75) !important;
}

.ril-toolbar__item {
  button {
    outline: none;
  }
}

[id$="ModalMount"] {
  .ant-modal-mask,
  .ant-modal-wrap {
    position: absolute;
  }

  .confirmation-modal {
    .ant-modal-body {
      text-align: center;
      padding: 20px 15px 15px;
    }
  }

  .confirmation-modal.extra-space {
    .ant-modal-body {
      padding: 30px;
    }
  }
}

.tab-layout.small-layout {
  .confirmation-modal {
    .align-footer-box {
      &.is-delete {
        .ant-form-item-control-input-content {
          .confirmation-footer {
            width: 100%;

            .ant-space-item {
              flex-basis: 50%;

              button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.check-box-cus {
  .ant-checkbox + span {
    padding: 0;
  }

  .check-box-element {
    display: flex;

    &.popover {
      img, svg {
        height: 12px;
        width: 12px;
      }
    }

    span:last-child {
      padding: 0 8px;
    }
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-input {
    + span {
      background-image: url("../images/svgs/check-blank-new.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:checked + span {
      background-image: url("../images/svgs/check-active.svg");
    }
  }

  .ant-checkbox-checked::after {
    border: none !important;
    content: none !important;
  }

  .ant-checkbox-inner {
    background-color: unset !important;
    border: none !important;
    transition: none !important;

    &::after {
      content: none !important;
    }
  }
}

.ant-modal-mask {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden !important;
}

.display-none {
  display: none;
}

.pointer-events {
  pointer-events: none !important;
}

.folder-items-container .image-box .image-full-screen-icon {
  position: absolute;
  top: 21px;
  right: 21px;
  height: 18px;
  width: 18px;
  user-select: none;
}

.border-tl-none {
  border-top-left-radius: 0 !important;
}

.border-tr-none {
  border-top-right-radius: 0 !important;
}

.home-activity, .fullscreen-folder-modal.home-folder {
  .ant-scroll-number-custom-component {
    background: $home-dark-grey !important;
  }

  .ant-modal-body {
    border: 3px solid $thick-green !important;
  }

  #appear-home-spark-on-top {
    &.folder-outer {
      .folder-body, .folder-header-new {
        border: 2px solid $home-light-grey !important;
      }

      .folder-header, .folder-header-new {
        border-bottom: none !important;
      }

      .folder-body {
        border-top: none !important;
      }
    }
  }

  .folder-header-new {
    background-color: $home-grey !important;
    .title {
      &:before, &:after {
        background-color: $home-title-green !important;
      }
      .ant-typography-ellipsis-single-line {
        &:before {
          background-image: linear-gradient(to right, rgba(228, 240, 237, 0.5) 0%, $home-title-green 100%) !important;
        }
      }
    }
  }

  .folder-header {
    .icons {
      background-image: linear-gradient(to right, rgba(217, 217, 255, 0.5) 0, $light-violet 25%) !important;
    }
  }

  .compact-view-buttons {
    background-color: $button-gray-color;
  }
  .no-items-yet {
    width: 100%;
  }
}

[data-rbd-drag-handle-context-id] {
  cursor: pointer !important;
}

.no-folder-item {
  text-align: center;
}

.rotate-right {
  transform: rotate(90deg) !important;
}

.rotate-left {
  transform: rotate(270deg) !important;
}

.rotate-upside-down {
  transform: rotate(180deg) !important;
}

.rotate-none {
  transform: rotate(0deg) !important;
}

.tab-layout {
  &.folders {
    .action-btn {
      display: flex;
      justify-content: flex-end;
      padding: 5px;
    }
  }
}

#appear-home-on-top {
  &.selected-node {
    .ant-badge {
      .ant-scroll-number-custom-component {
        background-color: $home-dark-grey !important;
      }
    }

    .home-folder-outer {
      .compact-view-buttons {
        border: 2px solid $home-light-grey !important;
      }

      .folder-header-new, .folder-body {
        border: 2px solid $home-light-grey !important;
      }

      .folder-header-new {
        border-bottom: none !important;
      }

      .folder-body {
        border-top: none !important;
      }

      .folder-view-icons {
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        z-index: 9999;
        .icons-group {
          width: 97%;
          height: 51px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 23px;
        }
      }

      .expanded-addbar-container {
        position: relative;
        background: transparent;
        border: 0.5px dashed $pink;
        border-radius: 5px !important;
        box-shadow: none;
      }
    }

    .addbar-container {
      left: 0;
      border-radius: 0 0 15px 15px;
    }

    .folder-outer {
      .compact-view-buttons {
        background-color: $home-title-green;

        .arrow-icon-wrapper {
          background-color: $home-grey;
        }
      }

      .folder-header-new {
        background-color: $home-grey;

        .title {
          &:before, &:after {
            background-color: $home-title-green;
          }
          .ant-typography-ellipsis-single-line {
            &:before {
              background-image: linear-gradient(to right, rgba(224, 238, 254, 0.5) 0%, $home-title-green 100%) !important;
            }
          }
        }
      }
    }
  }
}

.archived-container {
  height: 47px;
  padding: 20px 0 20px 16px;
  border-top: 2px solid $mild-grey;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
  .archived-folder-icon {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.form-inputs.signature {
  .title {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
  }
  & + .align-footer-box {
    .ant-form-item-control-input-content {
      justify-content: flex-end;
    }
  }
}

.input.with-switch, .signature-avatar {
  .ant-switch {
    height: 31px;
    width: 50px;

    .ant-switch-handle {
      height: 27px;
      width: 27px;

      &:before {
        border-radius: 50%;
      }
    }

    &.ant-switch-checked {
      background-color: $mild-green;

      .ant-switch-handle {
        left: calc(100% - 27px - 2px);
      }

      &:focus {
        -webkit-box-shadow: 0 0 0 2px rgba(52, 199, 89, 0.2);
        box-shadow: 0 0 0 2px rgba(52, 199, 89, 0.2);
      }
    }
  }

  .ant-input-group-addon {
    background-color: unset;
    border: none;
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  //for the phone number field only, these styles are getting applied
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .PhoneInput{
      width: 73%;
    }
    :focus {
      outline: none !important;
    }
    .phone-number-switch-button {
      .ant-form-item-control-input {
        min-height: 64px;
      }
      margin: 0 12px 0 0;
    }
  }
}

.ant-slider-tooltip {
  z-index: 1000;
}
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 1000 !important;
  }
}
// TODO: need to fix z-index issue for free space
.tab-layout.content.activities{
  z-index: 1;
  border-right: 3px solid rgba(0, 0, 0, 0.1);
}

.folder-body {
  [data-rbd-draggable-context-id], .static-activity-item {
    &:last-child {
      li.folder-items-container {
        margin-bottom: 0px !important;
      }
    }
  }
  [data-rbd-draggable-context-id]:last-child {
    margin-bottom: 60px !important;
  }
}

.ant-modal-header {
  border-bottom: hidden;
}

#sharedModalMount {
  .ant-modal-mask, .ant-modal-wrap {
    position: fixed;
  }
}

.ant-notification-topRight {
  z-index: 99999;
}

.ant-notification-topRight {
  z-index: 99999;
}

.ant-notification-topRight {
  z-index: 99999;
}

.selected-node {
  .folder-body {
    [data-rbd-draggable-context-id] {
      &:last-child {
        margin-bottom: 0!important;
      }
    }
  }
}

.ant-tooltip-inner {
  & .tooltip-title {
    font-weight: 700;
  }
}

.media-device {
  background: $light-green;
}
.video-js {
  .vjs-record-canvas {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    canvas {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
  video {
    object-fit: cover;
    transform: none;
  }
  wave {
    width: 100%;
    height: 56px !important;
    position: absolute !important;
    top: 40%;
  }
  .vjs-control {
    height: 100% !important;
  }
  &.myVideo-dimensions, &.myImage-dimensions, &.myAudio-dimensions {
    width: 100%;
    background: #F4F4F4;
    object-fit: cover;
    transform: none;
  }
  &.myImage-dimensions {
    .vjs-control-bar {
      background: none;
    }
  }
  &.myVideo-dimensions, &.myAudio-dimensions {
    .vjs-control-bar {
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .vjs-control-bar {
    .vjs-camera-button {
      width: 45px !important;
      height: 45px !important;
      position: absolute;
      top: 135%;
      left: 50%;
      background: url("../images/svgs/captureCameraIcon.svg") no-repeat;
      .vjs-control {
        .vjs-icon-photo-camera {
          &:before {
            content: '';
          }
        }
      }
    }
    .vjs-icon-record-start {
      display: none;
    }
    .vjs-icon-record-stop {
      display: none;
    }
  }
  .vjs-record-canvas {
    margin-right: 60px;
  }
}
#myAudio {
  .vjs-icon-audio-perm {
    display: block;
    position: absolute;
    width: 45px;
    height: 45px !important;
    top: 420px;
    left: 50%;
    z-index: 2;
    background: url("../images/svgs/recordAudioIcon.svg") no-repeat;
    cursor: pointer;
  }
  .vjs-icon-record-start, .vjs-icon-record-stop {
    display: block !important;
    width: 45px !important;
    height: 45px !important;
    position: absolute;
    top: 135%;
    left: 50%;
    z-index: 1;
    cursor: pointer;
    background: url("../images/svgs/recordAudioIcon.svg") no-repeat;
  }
  wave {
    image-rendering: pixelated;
  }
}
.record-video-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-down {
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 36px;
  color: $white;
}
svg {
  vertical-align: middle;
}
.ant-modal-close-x {
  svg {
    width: 24px;
    height: 24px;
  }
}

.capture-skeleton-container {
  height: 110px;
  &.full-screen {
    padding: 0 48px;
  }
  padding: 0 14px;
  .ant-list-item {
    max-width: 204px;
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    padding: 0;
  }
  .captures-skeleton {
    display: flex;
    .ant-skeleton-content {
      display: flex;
      .ant-skeleton-title {
        display: flex;
        width: 204px !important;
        height: 110px;
        background: $mercury;
        border-radius: 16px;
      }
    }
    &.lotion {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $lotion;
        }
      }
    }
    &.bright-gray {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $bright-gray;
        }
      }
    }
    &.blue-chalk {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $blue-chalk;
        }
      }
    }
    &.mercury {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $mercury;
        }
      }
    }
    &.geyser {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $geyser;
        }
      }
    }
    &.iron {
      .ant-skeleton-content {
        .ant-skeleton-title {
          background: $iron;
        }
      }
    }
  }
}

.folder-skeleton-container {
  display: flex;
  padding: 14px;
  &.home-body-container {
    &.home-folder-skeleton {
      padding: 14px;
    }
  }
  &.board-folders {
    &.board-folder-skeleton {
      padding: 0;
    }
    .active-section {
      height: 100%;
      padding: 1px 24px 16px 22px;
      overflow: auto;
      border: 2px dashed transparent;
      margin-bottom: 24px;
      &.adjust-padding {
        height: calc(100vh - 170px) !important;
        padding: 20px 24px 0px 22px;
      }
      &.adjust-padding:has(.cloud-list) {
        padding: 20px 24px 20px 22px;
      }
      &.adjust-boards-padding {
        padding: 22px 22px 0 22px;
        height: calc(100vh - 230px) !important;
      }
      &.bordered {
        border: 2px dashed $dropzone-background-color;
        border-radius: 4px;
        background-color: #ECF6FE;
      }
    }
    .active-cloud-area-section-drop-zone{
      display: flex;
      flex-direction: column;
      flex: 1;

      .ant-collapse{
        .ant-collapse-item{
          border-bottom: 1px solid $light-blue-gray;
        }
        .ant-collapse-item-active{
          padding-bottom: 24px; //if the panel gets' opened then only this padding gets opened
        }
        .ant-collapse-item:last-of-type{
          border-bottom: 0;
        }
      }
    }
    .myfiles-empty-drop-zone{
      flex: 1;
      //for the archive section positioning at the bottom and also for not to jumps up
      height: calc(100vh - 210px);
      overflow: auto;
    }
    .active-collapse {
      border: 2px solid transparent;
      margin: 0 6px 6px 6px;
      &.bordered {
        border: 2px dashed $dropzone-background-color;
        border-radius: 4px;
        background-color: #ECF6FE;
        .ant-list-item{
          background-color: #ECF6FE;
          border-radius: 14px;
        }
      }
    }
    .ant-collapse-header {
      padding: 12px 10px;
    }
    .ant-collapse-content-box {
      padding: 16px 10px;
    }
  }
  .upper-skeleton-container {
    display: flex;
    gap: 156px;
  }
  .lower-skeleton-container {
    width: 265px;
    display: flex;
    gap: 37px;
  }
  .full-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 265px;
        height: 443px;
        background: $input-background-gray;
        border-radius: 16px;
      }
    }
  }
  .compact-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        position: absolute;
        width: 265px;
        height: 140px;
        left: 100px;
        top: 100px;
        background: $lavender;
        border-radius: 16px;
      }
    }
  }
  .bar-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 265px;
        height: 40px;
        background: $input-background-gray;
        border-radius: 10px;
      }
    }
  }
  .free-space-skeleton {
    &.compact-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          position: absolute;
          width: 265px;
          height: 140px;
          left: 355px;
          top: 274px;
          background: $lavender;
          border-radius: 16px;
        }
      }
    }
  }
  .folder-skeleton {
    &.ant-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 265px !important;
          height: 140px !important;
          background: $input-background-gray !important;
          border-radius: 16px !important;
        }
      }
    }
    &.compact-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          position: unset;
          margin-left: 65px;
          background: $lavender !important;
        }
      }
    }
  }
}

.account-settings {
  .ant-form-item-control-input-content {
    :focus {
      outline: none !important;
    }
  }
}
svg {
  vertical-align: middle;
}
.ant-modal-close-x {
  svg {
    width: 24px;
    height: 24px;
  }
}

#hideMe {
  -moz-animation: fadeOut 0s ease-in 1s forwards;
  -webkit-animation: fadeOut 0s ease-in 1s forwards;
  -o-animation: fadeOut 0s ease-in 1s forwards;
  animation: fadeOut 0s ease-in 1s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#showMe{
  visibility: visible;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

li.ant-list-item.folder-items-container {
  &.insertion-line {
    background-color: transparent !important;
    padding: 0 !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.ant-menu-inline {
  .ant-menu {
    & > .ant-menu-item-selected {
      background-color: var(--surface-theme-dark);
    }
    .ant-menu-submenu-selected {
      & > .ant-menu-submenu-title {
        background-color: var(--surface-theme-dark);
      }
    }
  }
  .ant-menu-submenu-selected {
    & > .ant-menu-submenu-title {
      background-color: var(--surface-theme-dark);
    }
  }
  & .ant-menu-item::after {
    border-right: 0px;
  }
}

.my-drag-overlay {
  opacity: 0.5;
}

.ant-dropdown-menu-submenu {
  .ant-dropdown-menu-title-content {
    color: $blue-gray;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.action-button {
  padding: 8px 0;
  background-color: #279af1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0px;
  z-index: 12;
  width: 99.5%;
  .select-all, .delete, .download, .move, .create{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    width: 50%;
    color: $white;
    cursor: pointer;
    img, svg {
      width: 18px;
      height: 18px;
    }
  }
  .download {
    &.download-icon-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .create{
    &.create-icon-disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .small {
    font-size: 12px;
    padding-left: 10px;
  }
  button {
    width: 50%;
    border-radius: 0;
    font-size: 14px;
    &:nth-child(2) {
      border-left: 1px solid $split-blue-color;
    }
  }
}

//this is only for the action-button-bar from the cloudarea
.action-button-item{
  z-index: 113;
}

.jots-item-section {
  flex: 1;
  .ant-collapse-content-active{
    .ant-collapse-content-box {
      #jots-items-drop-zone {
        height: calc(100vh - 100px);
        overflow: scroll;
        #items {
          .ant-checkbox-group {
            flex: 1;
            .ant-list-split {
              flex: 1;
            }
          }
        }
      }
    }
  }
  .ant-checkbox-group .ant-list-item {
    box-shadow: unset!important;
    margin-bottom: unset!important;
    .ant-checkbox-wrapper {
      position: initial!important;
    }
  }
}
.item-dragging{
  border: 3px dashed $dropzone-background-color !important;
}
.ant-checkbox-group:has(.signature-list-modal) {
  display: unset;
}

.jots-collapsible-panel {
  .ant-list-item {
    .action-button {
      bottom: 0;
      z-index: 112;
    }
  }
}
.item-card {
  .ant-card-head {
    height: 40px!important;
    min-height: 40px!important;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.d-none{
  display: none;
}
.sortable-document {
  width: fit-content;
}
.item-fixed-height {
  height: 130px;
  .folder-items-container {
    height: inherit;
    overflow: hidden;
    .full-screen {
      height: fit-content;
      .item-edit{
        top: 10px;
      }
    }
    .video-component{
      height: inherit;
      .video-player{
        height: inherit;
        img {
          min-height: 50px;
          height: 100%;
        }
      }
    }
    .image-component{
      height: inherit;
      .capture-image-details {
        height: inherit;
        img {
          min-height: 50px;
          height: 100%;
        }
      }
    }
  }
}
.document-full-screen-item-fixed-height {
  height: 150px;
  margin-bottom: 20px;
  .folder-items-container {
    height: inherit;
    overflow: hidden;
    .full-screen {
      height: fit-content;
      .item-edit{
        top: 10px;
      }
    }
    .video-component{
      height: inherit;
      .video-player{
        height: inherit;
        img {
          min-height: 50px;
          height: 100%;
        }
      }
    }
    .image-component{
      height: inherit;
      .capture-image-details {
        height: inherit;
        img {
          min-height: 50px;
          height: 100%;
        }
      }
    }
  }
}
.document-fixed-height {
  height: 200px;
  margin: 10px 0px;
  .sortable-document-fixed-height{
    height: inherit;
    .ant-list-item{
      height: inherit;
      .document-fixed-height-droppable {
        height: inherit;
        .folder-outer{
          height: inherit;
          .empty-document{
            svg {
              padding-top: 35px;
            }
          }
        }
      }
    }
  }
}

//when multiple tabs like JOTS+INDEX[Expanded]+MYFILES, this style will restrict the width, this makes the sider search icon visible even there is a re-order toggle present
.sider-container.expanded ~ #folderModalMount{
  .board-folder{
    .board-header.multi-window.with-capture{
      .folder-header-component{
        .reorder-toggle{
          margin-right:40px;
        }
      }
    }
  }
}
#folderModalMount:has(.on-boarding-model) {
  .ant-modal-root{
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.49);
    }
  }
}
.ant-menu-submenu-selected {
    & > .ant-menu-submenu-title {
        color: $primary-color !important;
    }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $primary-color !important;
}
.ant-spin {
  color: $primary-color !important;
}
.ant-btn-primary {
  background-color: $primary-color;
}
input::selection{
  background-color: $primary-color;
  color: $white;
}


#tour-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  cursor: not-allowed;
}

.pro-tip-popover {
  width: 300px;
  border-radius: 24px;
  border: 2px solid #FFA303;
  background: #FFF;
  padding: 8px;
  .f-row {
    display: flex;
    flex-direction: row;
  }
  .f-col {
    display: flex;
    flex-direction: column;
  }
  .g_8 {
    gap: 8px;
  }
  .content-container {
    border-radius: 16px;
    background: #FFFDE7;
    padding: 8px;
    .tip {
      color: #FFA303;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .description {
      display: flex;
      padding: 0 28px;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      span {
        font-weight: 700;
      }
    }
  }
}

.common-disabled {
  opacity: 0.5;
  pointer-events: none !important;
}

.onboarding-slides.ant-popover-placement-right > .ant-popover-arrow{
  top: 50%;
  transform: translateY(-50%) translateX(-96%) rotate(-93deg);
}

.sc6t1 {
  padding: 38px 80px;
}

.file-upload-status {
  .description {
    .title {
      height: 20px !important;
    }
  }
  .ant-progress {
    .ant-progress-outer {
      .ant-progress-inner {
        height: 4px !important;
        margin-top: 4px !important;
      }
    }
  }
}
.folder-header-component {
  .filter-wrapper {
    display: flex;
    gap: 12px;
  }
}

#folderModalMount{
  .tab-layout{
    width: 100%;
    &.full-width {
      width: 100%;
      &.capture-list {
        overflow: auto !important;
      }
    }
  }
  .ant-notification {
    &.ant-notification-bottomLeft {
      &:has(.my-files-collapsed-sider-free-space-progress){
        left: initial !important;
        bottom: 85px !important;
        margin-left: 54px !important;
      }
      &:has(.my-files-expanded-sider-free-space-progress){
        left: initial !important;
        bottom: 85px !important;
        margin-left: 284px !important;
      }
    }
  }
  .archive-section-container.fullOpen {
    height: calc(100% - 70px);
  }
  .archive-section-container.partialOpen {
    height: calc(30% + 18px);
  }
}
.social-register-login-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 36px;
  border-bottom: 1px solid #D9DFE8;
  h2 {
    margin: 0;
    line-height: unset;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: $black;
  }
  .terms {
    margin-bottom: 20px;
    p {
      text-align: center;
      color: #7A7A7A;
      font-size: 14px;
      font-weight: 400;
      margin: 0 4px;
      a {
        color: #FFA303;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .register-text {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
  .social-buttons {
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin: auto;
    .apple-auth-btn {
      font-size: 0;
      height: 40px;
      width: 40px;
      box-sizing: border-box;
      border-radius: 50%;
      padding: 0;
    }
  }
}

.common-context-menu {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.50);
  svg {
    width: 16px;
    height: 16px;
  }
}

.highlight-dnd-item {
  border: 2px solid $dropzone-background-color;
  filter: drop-shadow(0px 0px 12px rgba(39, 154, 241, 0.24));
  border-radius: 4px;
}

.highlight-container {
  border: 2px dashed $dropzone-background-color;
}

.reorder-background {
  border: 2px dashed #279AF1;
  background: #ECF6FE;
  border-radius: inherit;
}

.selected-count-badge {
  position: absolute;
  top: 2px;
  margin-left: 48px;
  border-radius: 4px;
  background: #FD6566;
  min-width: 17px;
  text-align: center;
  min-height: 17px;
  padding: 2px;
  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mr-5 {
  margin-right: 5px;
}