@import "src/styles/variables";

.mobile-app-view {
  text-align: center;
  padding: 16px;
  background-image: url("../../images/svgs/bgLogin.svg");
  background-size: cover;
  overflow: scroll;
  p, h3 {
    color: $black;
    margin-bottom: 0;
  }

  .logo {
    margin: 40px 0 50px 0;

    img, svg {
      height: 65px;
      margin-bottom: 15px;
    }

    p {
      font-size: 17px;
      font-weight: 600;
    }
  }

  .mobile-view-card {
    padding: 50px 20px 30px;
    border-radius: 15px;
    background: $white;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    margin-bottom: 20px;


    img, svg {
      height: 67px;
    }

    h3 {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .ant-btn {
      color: $white;
      height: 35px;
      width: 150px;
      background-color: $black;
      border-color: $black;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;


    }
  }
}
.qr-code{
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  align-items: center;
}

.play-store-qr svg,
.app-store-qr svg {
  width: 90px;
}

@media (max-width: 768px) {
  .mobile-view-card {
    max-height: calc(100vh - 100px);
    padding: 40px 10px;
  }
}
