@import "src/styles/variables";

.form-modal,
.confirmation-modal,
.verification-modal,
.preview-modal {
  .ant-modal-content {
    border-radius: 24px;
    padding: 0;

    .ant-modal-body {
      padding: 50px;
    }

    .title {
      color: $black;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: normal;

      .description {
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
      }
    }

    .content {
      color: $black;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .ant-btn-primary {
      width: 113px;
      height: 39px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 32px;
    }

    .ant-modal-close {
      top: 40px;
      right: 40px;
    }

    .ant-btn[type="submit"],
    .btn-secondary {
      font-weight: bold;
    }

    .ant-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .secondary-type{
      gap: 8px;
      .cancel-download{
        width:fit-content;
      }
    }
  }

  .align-footer-box {
    margin-bottom: 0;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}
.updated-modal{
  &.jots-new-container{
    width: 400px !important;
    .title{
      font-size: 18px;
      margin-bottom: 32px !important;
    }
  }
  .ant-modal-body{
    padding: 24px 24px 40px 24px !important;
  }
  .ant-modal-close:hover {
    background-color: #FFFFFF;
  }
  .align-footer-box{
    margin-top: 38px !important;
  }
}
.form-modal {
  .avatar-thumbnail {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    img,
    svg {
      border-radius: 10px;
    }
  }

  &.caption-modal {
    .align-footer-box {
      margin-bottom: 0;

      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;

        .btn-lg {
          &.btn-danger-outline {
            margin-right: 8px;
            flex-grow: 2;
          }

          &.btn-grey {
            flex-grow: 1;
            margin-right: 8px;
          }
        }

        .btn-secondary {
          flex-grow: 1;
        }
      }
    }
  }
  #changePasswordForm,
  #accountDeactivationForm {
    .form-inputs {
      .input.focus,
      .input.has-value {
        .ant-input-password {
          .ant-input-suffix {
            .ant-input-password-icon {
              transform: translateY(10%) !important;
              height: 24px !important;
              width: 24px !important;
            }
          }
        }
      }
    }
  }
  .do-this-later {
    display: flex;
    justify-content: center;
    color: $open-map-btn-color;
    &.edit-container-visible{
      margin-bottom: 55px;
    }
    .ant-btn-link {
      font-size: 14px;
      font-weight: bold;
      color: $primary-color;
    }
    .ant-btn-link:hover {
      background: #F5F5F5;
      color: #FFA303;
    }
    & + .align-footer-box {
      margin-top: 50px;
    }
    &.home-loader {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.confirmation-modal {
  text-align: center;
  max-width: 370px;

  .ant-modal-body {
    .modal-logo {
      background: no-repeat;
      height: 100px;
      width: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
    }

    .modal-title {
      font-size: 16px;
      font-weight: 500;
      margin: 20px auto 0;
    }

    & > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &.active-session-info {
    & .ant-modal-confirm-btns {
      float: none;

      button{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.pre-config {
    width: 372px!important;
    .ant-modal-content {
      padding: 40px!important;
    }
    .modal-title {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.5!important;
      color: #0B1242;
      margin: 0 0 10px 0;
      padding: 0;
    }
    .info {
      color: #4E5F7E!important;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 24px!important;
    }
    .ant-modal-footer {
      div {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        button {
          width: 165px;
          background: #fff;
          cursor: pointer;
          color: $dropzone-background-color;
          border-color: $dropzone-background-color;
          font-size: 18px;
          &.submit-button {
            background: $dropzone-background-color;
            color: $white;
          }
        }
      }
    }
  }
}

.alert-modal {
  &.pre-config-confirmation {
    .ant-modal-content {
      padding: 32px 40px !important;
      border-radius: 16px;
      width: 471px;
      .ant-modal-body {
        .modal-title {
          color: #0B1242;
          font-size: 28px;
          margin: 0 0 16px 0;
          line-height: 1.5;
          font-weight: 500;
        }
        .info {
          color: #4E5F7E;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 10px !important;
          & span {
            font-weight: 700;
          }
        }
      }
    }
    .ant-modal-footer {
      div {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        button {
          width: 250px;
          background: $input-background-gray;
          cursor: pointer;
          color: $black;
          height: 45px!important;
          font-size: 18px;
          border-radius: 6px;
          &.submit-button {
            margin-top: 10px;
            background: $dropzone-background-color;
            color: $white;
          }
          &.secondary-button {
            border: 1px solid #279AF1;
            color: #279AF1;
            background: #fff;
          }
        }
      }
    }
  }
  &.dnd-confirmation-alert {
    .ant-modal-content {
      padding: 32px 24px !important;
      border-radius: 16px;
      width: 414px;
      .ant-modal-body {
        .modal-title {
          color: #0B1242;
          font-size: 28px;
          margin: 0 0 16px 0;
          line-height: 1.5;
          font-weight: 500;
        }
        .info {
          color: #4E5F7E;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 10px !important;
          & span {
            font-weight: 700;
          }
        }
      }
    }
    .ant-modal-footer {
      div {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        button {
          width: 108px;
          background: $input-background-gray;
          cursor: pointer;
          color: $black;
          height: 45px!important;
          font-size: 18px;
          border-radius: 6px;
          &.submit-button {
            margin-top: 10px;
            background: $dropzone-background-color;
            color: $white;
          }
          &.secondary-button {
            border: 1px solid #279AF1;
            color: #279AF1;
            background: #fff;
          }
        }
      }
      .ant-checkbox-wrapper {
        margin-top: 16px;
        color: #4E5F7E;
        .ant-radio-inner {
          border-radius: 4px;
        }
      }
    }
  }
  &.publish-info-alert {
    .ant-modal-content {
      padding: 64px 24px !important;
      border-radius: 16px;
      width: 414px;
      .ant-modal-body {
        .modal-title {
          color: #0B1242;
          font-size: 28px;
          margin: 0 0 16px 0;
          line-height: 1.5;
          font-weight: 500;
        }
        .info {
          color: #4E5F7E;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 10px !important;
          & span {
            font-weight: 700;
          }
        }
      }
    }
    .ant-modal-footer {
      div {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        button {
          width: 250px;
          background: $input-background-gray;
          cursor: pointer;
          color: $black;
          height: 45px!important;
          font-size: 18px;
          border-radius: 6px;
          &.submit-button {
            margin-top: 10px;
            background: $dropzone-background-color;
            color: $white;
          }
          &.secondary-button {
            border: 1px solid #279AF1;
            color: #279AF1;
            background: #fff;
          }
        }
      }
    }
  }
}

//footer button box
.text-align-center {
  text-align: center;
  margin-bottom: 0;
}

.align-footer-box {
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}
//need to check
.form-modal,
.verification-modal {
  .ant-modal-close {
    top: 33px !important;
    right: 50px !important;
  }

  .ant-modal-close,
  .ant-modal-close-x {
    height: 24px;
    width: 24px;
  }

  .ant-modal-close {
    .ant-modal-close-x {
      img,
      svg {
        position: relative;
      }
      .modal-close-icon{
        position: absolute;
        top: -10px;
        right: -20px;
      }
    }
  }
}

.form-modal {
  &.new-activity-modal,
  &.signature-modal,
  &.contact-modal {
    .ant-modal-header {
      padding: 0;
      border-radius: 8px 8px 0 0;
      .ant-modal-title {
        .info-banner-container {
          &.visible {
            border-radius: 8px 8px 0 0;

            .info-banner-icon {
              border-top-left-radius: 8px;
            }
          }
        }
      }
    }
    &.new-activity-modal {
      .title {
        display: flex;
        gap: 8px;
      }
    }
    &.show-tip-banner-visible {
      .ant-modal-close {
        top: 100px !important;
      }
    }
    &.show-tip-banner-invisible {
      .ant-modal-close {
        transition: top 0.5s ease-out;
      }
    }
  }
  &.signature-modal,
  &.contact-modal {
    &.show-tip-banner-visible {
      .ant-modal-content {
        .ant-modal-close {
          top: 125px !important;
        }
      }
    }
  }
  &.jots-document-properties-modal, &.duplicate-node-modal, &.capture-add-bar-modal {
    .ant-modal-content {
      .ant-modal-close {
        top: 49px !important;
        right: 50px !important;
      }
    }
  }
}

.register-form,
.form-modal {
  @media only screen and (max-width: 759px) {
    .ant-modal-content {
      .ant-modal-body {
        padding: 15px;
      }
    }
    .ant-modal-close {
      top: 0px !important;
      right: 15px !important;
    }
  }
}

.move-modal {
  .ant-modal-content {
    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: $black;
      margin: 10px auto 25px auto !important;
    }
  }

  .ant-modal-close {
    top: 25px !important;
    right: 25px !important;
  }

  .move-modal-body {
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon {
        margin-right: 10px;
      }
      .name {
      }
      .create-folder {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 0;
        span {
          height: 15px;
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: $black;
        }
      }
    }
    .content {
      overflow: auto;
      min-height: 350px;
      border-radius: 4px;
      background: transparent;
      box-sizing: border-box;
      padding: 10px;
      gap: 10px;
      height: 424px;
      border: 2px dashed rgba(0, 0, 0, 0.3);

      .ant-menu-inline {
        border-right: none !important;
      }

      .ant-menu-item::after {
        content: none;
      }

      .ant-menu-sub {
        &.ant-menu-inline {
          background: $white !important;
        }
        .ant-menu-item:first-child {
          border-bottom: 1px solid #eaeaea;
        }
      }
      .ant-menu-title-content {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $black;
      }

      span {
        & .ant-menu-title-content {
          font-weight: 400;
        }
      }

      .ant-menu-submenu-title {
        padding-bottom: 10px;
        height: 50px;
        border-bottom: 1px solid #eaeaea;
        img {
          height: 40px;
          width: 40px;
          border-radius: 4px;
          margin-right: 15px;
        }
      }
      .ant-menu-inline {
        :last-child {
          .ant-menu-submenu-title {
            border-bottom: none;
          }
        }
      }
    }

    .captures {
      height: auto;
      min-height: unset;
    }
  }
}
.disable-close {
  .ant-modal-close {
    pointer-events: none;
    opacity: 0.5;
  }
}

.default-check-box {
  .ant-checkbox {
    div {
      display: none;
    }
  }
  .ant-checkbox-input {
    + span {
      width: 16px !important;
      height: 16px !important;
      background-image: none;
      border-radius: 4px !important;
      border: 1px solid #B8B8B8 !important;
    }
    &:checked + span {
      background-image: url("../../images/svgs/checkbox-orange.svg");
      border: none !important;
      box-shadow: none !important;
    }
  }
}