.common-notification-alert {
  &.server-error {
    width: 308px;
    border-radius: 16px;
    border: 2px solid #fec3c3;
    background: #fff;
    box-shadow: 0 2px 24px 0 #dcdcdc;
    padding: 12px;
    right: 45px;
    .info-icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffebeb;
      border-radius: 50%;
    }
    .ant-notification-notice-message {
      color: #0b1242;
      font-size: 12px;
      font-weight: 600;
    }
    .ant-notification-notice-description {
      color: #8f8f8f;
      font-size: 12px;
      font-weight: 400;
      :last-child {
        color: #f10404;
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}
