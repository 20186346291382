@import "src/styles/_variables";

.no-items-yet{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  &.jots-no-item{
    text-wrap: nowrap;
  }
  &.board-group{
    margin-top: calc(50vh - 215px);
    padding-top: 0!important;
  }

  .no-items-description{
    font-family:'Roboto';
    font-size:14px;
    font-weight:400;
    color: $cool-gray;
    width:55%;
    margin-top:10px;
    text-align:center;
    &.capture-noitem-description{
      font-weight: 500;
      font-size: 20px;
      margin-top: 24px;
    }
  }
  &.archived-section {
    padding: 12px;
    .ant-collapse-header {
      display: none !important;
    }
    .archive-description {
      color: #A6B3C9;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      padding: 24px 0 14px 0
    }
    .option {
      text-align: center;
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}