@import "../../styles/variables";

.initial-loading {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  &.trash-loader {
    z-index: 1000;
    position: absolute;
    left: 50%;
  }
}

.browser-warm-load-modal {
  .ant-modal-content {
    background: $white;
    box-shadow: $browser-warm-load-modal-box-shadow;
    border-radius: 14px;
    .ant-modal-header {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 14px;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px 18px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    .ant-modal-footer {
      padding: 0 0 31px 0;
      text-align: center;
      border: none;
      button {
        width: 100%;
        max-width: 200px;
        height: 42px;
        background: $secondary-color;
        border-radius: 8px;
        font-weight: 700;
        font-size: 18px;
        color: $white;
      }
    }
  }
}