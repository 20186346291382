$themes: (
  'theme-gold': (
    'colors': (
      'text-h1': #0B1242,
      'text-h2': #8F8F8F,
      'text-h3': #0B1242,
      'text-h4': #474747,
      'text-h5': #B8B8B8,
      'text-h6': #A3A3A3,
      'text-subtitle-1-dark': #474747,
      'text-subtitle-1-light': #CCCCCC,
      'text-subtitle-2': #666666,
      'text-subtitle-3': #474747,
      'text-body-1': #000000,
      'text-body-2': #B8B8B8,
      'text-body-3': #666666,
      'text-body-4': #B8B8B8,
      'text-caption': #A3A3A3,
      'surface-theme-dark': #E7C79C,
      'surface-theme-light': #F9F1E6,
      'surface-onboarding-theme-light': #F9F1E6,
      'surface-input-fields': #F5F5F5,
      'hovering-light': #F9F1E6,
      'hovering-dark': #F3E3CE,
      'hovering-darkest': #E7C79C,
      'borders-primary': #E7C79C,
      'borders-secondary': #E7C79C,
      'dividers-primary': #E0E0E0,
      'dividers-light': #F5F5F5,
      'dividers-neutral': #F5F5F5,
      'icons-primary': #0B1242,
      'icons-secondary': #FFA303,
      'icons-theme-lightest': #A3A3A3,
      'icons-theme-light': #B8B8B8,
      'icons-theme-dark': #666666,
      'icons-theme-darkest': #474747,
      'icons-inactive': #CCCCCC,
      'surface-search-field': #F5F5F5,
      'surface-search-field-placeholder': #A3A3A3,
      'text-search-detail-key' : #666666,
      'text-search-detail-value' : #666,
      'surface-seach-detail-empty-thumbnail' : #F5F5F5,
      'text-search-settings' : #666666,
      'text-dropdown': #0B1242,
      'search-filter-background': '#F9F1E6',
    ),
  ),
  'theme-grey': (
    'colors': (
      'text-h1': #4E5F7E,
      'text-h2': #8D9DB9,
      'text-h3': #0B1242,
      'text-h4': #4E5F7E,
      'text-h5': #BFC9D9,
      'text-h6': #A3A3A3,
      'text-subtitle-1-dark': #2B4E78,
      'text-subtitle-1-light': #BFC9D9,
      'text-subtitle-2': #4E5F7E,
      'text-subtitle-3': #2B4E78,
      'text-body-1': #0B1242,
      'text-body-2': #BFC9D9,
      'text-body-3': #4E5F7E,
      'text-body-4': #A6B3C9,
      'text-caption': #8D9DB9,
      'surface-theme-dark': #D9DFE8,
      'surface-theme-light': #F2F4F7,
      'surface-onboarding-theme-light': #E6EAF0,
      'surface-input-fields': #F2F4F7,
      'hovering-light': #F2F4F7,
      'hovering-dark': #D9DFE8,
      'hovering-darkest': #BFC9D9,
      'borders-primary': #D9DFE8,
      'borders-secondary': #D4DAED,
      'dividers-primary': #D9DFE8,
      'dividers-light': #F2F4F7,
      'dividers-neutral': #F5F5F5,
      'icons-primary': #0B1242,
      'icons-secondary': #FFA303,
      'icons-theme-lightest': #BFC9D9,
      'icons-theme-light': #A6B3C9,
      'icons-theme-dark': #4E5F7E,
      'icons-theme-darkest': #3E4C65,
      'icons-inactive': #CCCCCC,
      'surface-search-field': #F2F4F7,
      'surface-search-field-placeholder': #BFC9D9,
      'text-search-detail-key' : #7488AA,
      'text-search-detail-value' : #4E5F7E,
      'surface-seach-detail-empty-thumbnail' : #F2F4F7,
      'text-search-settings' : #8D9DB9,
      'text-dropdown': #000,
      'search-filter-background': '#D9DFE8',
    ),
  ),
);

// Global colors
@each $theme-name, $theme-vars in $themes {
  // Custom colors
  $colors: map-get($theme-vars, 'colors');
  // Common colors
  $theme: (
    'text-h1': map-get($colors, 'text-h1'),
    'text-h2': map-get($colors, 'text-h2'),
    'text-h3': map-get($colors, 'text-h3'),
    'text-h4': map-get($colors, 'text-h4'),
    'text-h5': map-get($colors, 'text-h5'),
    'text-h6': map-get($colors, 'text-h6'),
    'text-subtitle-1-dark': map-get($colors, 'text-subtitle-1-dark'),
    'text-subtitle-1-light': map-get($colors, 'text-subtitle-1-light'),
    'text-subtitle-2': map-get($colors, 'text-subtitle-2'),
    'text-subtitle-3': map-get($colors, 'text-subtitle-3'),
    'text-body-1': map-get($colors, 'text-body-1'),
    'text-body-2': map-get($colors, 'text-body-2'),
    'text-body-3': map-get($colors, 'text-body-3'),
    'text-body-4': map-get($colors, 'text-body-4'),
    'text-caption': map-get($colors, 'text-caption'),
    'surface-theme-dark': map-get($colors, 'surface-theme-dark'),
    'surface-theme-light': map-get($colors, 'surface-theme-light'),
    'surface-onboarding-theme-light': map-get($colors, 'surface-onboarding-theme-light'),
    'surface-input-fields': map-get($colors, 'surface-input-fields'),
    'hovering-light': map-get($colors, 'hovering-light'),
    'hovering-dark': map-get($colors, 'hovering-dark'),
    'hovering-darkest': map-get($colors, 'hovering-darkest'),
    'borders-primary': map-get($colors, 'borders-primary'),
    'borders-secondary': map-get($colors, 'borders-secondary'),
    'dividers-primary': map-get($colors, 'dividers-primary'),
    'dividers-light': map-get($colors, 'dividers-light'),
    'dividers-neutral': map-get($colors, 'dividers-neutral'),
    'icons-primary': map-get($colors, 'icons-primary'),
    'icons-secondary': map-get($colors, 'icons-secondary'),
    'icons-theme-lightest': map-get($colors, 'icons-theme-lightest'),
    'icons-theme-light': map-get($colors, 'icons-theme-light'),
    'icons-theme-dark': map-get($colors, 'icons-theme-dark'),
    'icons-theme-darkest': map-get($colors, 'icons-theme-darkest'),
    'icons-inactive': map-get($colors, 'icons-inactive'),
    'surface-search-field' : map-get($colors, 'surface-search-field'),
    'surface-search-field-placeholder' : map-get($colors, 'surface-search-field-placeholder'),
    'text-search-detail-key' : map-get($colors, 'text-search-detail-key'),
    'text-search-detail-value' : map-get($colors, 'text-search-detail-value'),
    'surface-seach-detail-empty-thumbnail' : map-get($colors, 'surface-seach-detail-empty-thumbnail'),
    'text-search-settings' : map-get($colors, 'text-search-settings'),
    'text-dropdown': map-get($colors, 'text-dropdown'),
    'search-filter-background': map-get($colors, 'search-filter-background'),
  );

  .#{$theme-name} {
    //// define a css variable for each color
    //@each $name, $color in $colors {
    //  --#{$name}: #{$color};
    //}

    // define a css variable for each theme color
    @each $name, $color in $theme {
      --#{$name}: #{$color};
    }
  }
}